import "./intro.scss"
import { ArrowDownwardSharp } from "@material-ui/icons"

export default function Intro() {

    return (
        <div className="intro" id="intro">
            <div className="left">
                <h2>Hello, my name is</h2>
                <h1>Brent Buffenbarger</h1>
                <h3>I am an engineer at Boeing and student at the University of Southern California.</h3>
                <a href="#portfolio">
                    <ArrowDownwardSharp className="icon" />
                </a>
            </div>
            <div className="right">
                <h2>Feel free to click and view my resume.</h2>
                <div className="bottom">
                    <img src="/assets/resume.png" alt="" onClick={() => window.open("https://brent-buffenbarger-resume.s3.us-west-1.amazonaws.com/Resume.pdf", "_blank")}/>
                </div>
            </div>
        </div>
    )
}
