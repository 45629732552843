export const highlightsPortfolio = [
    {
      id: 1,
      title: "Paper Trading Website",
      img:
        "/assets/img3.jpg",
      desc: "Click the image to view the site.",
      redir: "https://stock-search-hw8-angular.wl.r.appspot.com/",
      target: "_blank",
    },
    {
      id: 2,
      title: "Paper Trading Mobile App",
      img:
        "/assets/img1.jpg",
      desc: "Click the image to watch a video.",
      redir: "https://www.youtube.com/watch?v=pkh0pQ7D9Ls",
      target: "_blank",
    },
    {
      id: 3,
      title: "Tetris",
      img:
        "/assets/tetris.png",
      desc: "Click the image to play.",
      redir: "https://brent-buffenbarger.github.io/tetris/index.html",
      target: "_blank",
    },
  ];
  
  export const designPortfolio = [
    {
      id: 1,
      title: "Stock Data Website",
      img:
        "/assets/img3.jpg",
      desc: "Click the image to view the site.",
      redir: "https://stock-search-hw8-angular.wl.r.appspot.com/",
      target: "_blank",
    },
    {
      id: 2,
      title: "Stock Data Mobile App",
      img:
        "/assets/img1.jpg",
      desc: "Click the image to watch a video.",
      redir: "https://brent-buffenbarger.github.io/tetris/index.html",
      target: "_blank",
    },
  ];
  
  export const gameDevPortfolio = [
    {
      id: 1,
      title: "Tetris",
      img:
        "/assets/tetris.png",
      desc: "Click the image to play.",
      redir: "https://brent-buffenbarger.github.io/tetris/index.html",
      target: "_blank",
    },
    {
      id: 2,
      title: "Lunar Lander",
      img:
      "/assets/lunar.png",
      desc: "Click the image to play.",
      redir: "https://brent-buffenbarger.github.io/lunarLander/index.html",
      target: "_blank",
    },
    {
      id: 3,
      title: "Maze Game",
      img:
        "/assets/maze.png",
      desc: "Click the image to play.",
      redir: "https://brent-buffenbarger.github.io/maze/index.html",
      target: "_blank",
    },
  ];
  
  export const aiPortfolio = [
    {
      id: 1,
      title: "Alpha-beta GO player",
      img:
        "/assets/go.jpg",
      desc: "Click the image to visit repository.",
      redir: "https://github.com/brent-buffenbarger/GoAI",
      target: "_blank",
    },
    {
      id: 2,
      title: "Neural Network Digit Recognition",
      img:
        "/assets/digits.png",
      desc: "Click the image to visit repository.",
      redir: "https://github.com/brent-buffenbarger/DigitClassifier_NeuralNet",
      target: "_blank",
    },
  ];