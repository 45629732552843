import "./works.scss"
import { useState } from "react";

export default function Works() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const data = [
        {
            id: "1",
            icon: "./assets/boeing.png",
            company: "Boeing",
            title: "Product Security Engineer",
            start: "Aug. 2021",
            end: "present",
            location: "El Segundo, CA",
            desc:
            "I am currently working as a system administrator for the Boeing Test & Evaluation branch of Boeing's Satellite Systems division. In this role I am responsible for ensuring IT infrastructure solutions meet the requirements and schedule of a given program. Most days I work on configuring Windows and Linux systems so they can be used in various test phases. I also spend a lot of time troubleshooting issues that arise when test engineers are using the equipment.",
        },
        {
            id: "2",
            icon: "./assets/sdl.png",
            company: "Space Dynamics Laboratory",
            title: "Software Developer Assistant",
            start: "Dec. 2019",
            end: "Jun. 2021",
            location: "Logan, UT",
            desc:
            "I was responsible for writing unit tests for two large projects, which are written in C++. The main project I worked on was a suite of libraries that is heavily utilized by one of the Space Dynamic Laboratories largest pieces of software. My job was to choose a library within the project and increase the unit test coverage to at least 95% for each file. In order to write meaningful and robust tests, I had to read and understand code written by other developers. If my tests found a bug in the code, then I would report it to my team and work to fix the issue. This position gave me a lot of experience with various continuous integration products such as Git and Jenkins.",
        },
        {
            id: "3",
            icon: "./assets/goldman.png",
            company: "Goldman Sachs",
            title: "Intern",
            start: "May. 2019",
            end: "Aug. 2019",
            location: "Salt Lake City, UT",
            desc:
            "I completed this accounting-based internship at Goldman Sachs last summer whenever I was studying finance. My main duty was to ensure the integrity of the books and ledgers by identifying and reporting breaks between ledgers. I also helped to provide oversight of reconciliations related to the securities and cash ledgers. This internship allowed me to see what it is like to work in a professional work environment. I also had plenty of opportunities to meet with senior professionals, which allowed me to refine my networking skills.",
        },
    ];

    const handleClick = (dir) => {
        dir === "left" 
            ? setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : 2) 
            : setCurrentSlide(currentSlide < data.length - 1 ? currentSlide + 1 : 0);
    }

    return (
        <div className="works" id="works">
            <h1>Professional Experience</h1>
            <div className="slider" style={{transform: `translateX(-${currentSlide * 100}vw)`}}>
                {data.map((d) => (
                    <div key={d.id} className="container">
                        <div className="item">
                            <div className="header">
                                <div className="imgContainer">
                                    <img src={d.icon} alt="" />
                                </div>
                                <h2>{d.company}</h2>
                                <h3>{d.title}</h3>
                                <h4>{d.start} - {d.end} | {d.location}</h4>
                            </div>
                            <div className="content">
                                <p>{d.desc}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <img src="assets/arrow.png" className="arrow left" alt="" onClick={() => handleClick("left")}/>
            <img src="assets/arrow.png" className="arrow right" alt="" onClick={() => handleClick("right")}/>
        </div>
    );
}
