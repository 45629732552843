import "./contact.scss"
import { useState } from "react";
import emailjs from 'emailjs-com'

export default function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const submit = (e) => {
        e.preventDefault();
        if (email) {
            if (isValidEmail) {
                if (name && message) {
                    const serviceId = 'service_6xt06ik';
                    const templateId = 'template_h2rm7cb';
                    const userId = 'user_TISqP2pH4I3ic44M4bMUE';
                    const templateParams = {
                        name,
                        email,
                        message
                    };
        
                    emailjs.send(serviceId, templateId, templateParams, userId)
                        .then(response => console.log(response))
                        .then(error => console.log(error));
            
                    setName('');
                    setEmail('');
                    setMessage('');
                    setEmailSent(true);
                } else {
                    alert('Please fill in all fields.');
                }
            } else {
                alert('Please enter a valid email address.');
            }
        }
    }

    const isValidEmail = email => {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    };

    return (
        <div className="contact" id="contact">
            <div className="left">
                <img src="assets/shake.svg" alt="" />
            </div>
            <div className="right">
                <h2>Send me a message!</h2>
                <form onSubmit={submit}>
                    <input type="text" placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
                    <input type="email" placeholder="Email Address" value={email} onChange={e => setEmail(e.target.value)} />
                    <textarea placeholder="Your message" value={message} onChange={e => setMessage(e.target.value)}></textarea>
                    <button type="submit">Send Message</button>
                    {emailSent && <span>Thanks for your message. I will respond as soon as possible.</span>}
                </form>
            </div>
        </div>
    )
}
