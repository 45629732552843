import Navbar from "./components/navbar/Navbar";
import Intro from "./components/intro/Intro";
import Portfolio from "./components/portfolio/Portfolio";
import Works from "./components/works/Works";
import Contact from "./components/contact/Contact";
import Menu from "./components/menu/Menu";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./app.scss"
import { useState } from "react";

function App() {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <div className="app">
      <Navbar menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
      <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
              <div className="sections">
                <Intro />
                <Portfolio />
                <Works />
                <Contact />
              </div>
            } />
          <Route path='/tetris' render={() => (<Navigate to="/tetris/index.html" />)} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
