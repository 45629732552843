import PortfolioList from "../portfolio-list/PortfolioList"
import "./portfolio.scss"
import { highlightsPortfolio, gameDevPortfolio, aiPortfolio, designPortfolio } from "../../data";
import { useState, useEffect } from "react";

export default function Portfolio() {
    const [selected, setSelected] = useState("highlights");
    const [data, setData] = useState([]);
    const list = [
        {
            id: "highlights",
            title: "Highlights",
        },
        {
            id: "gameDev",
            title: "Game Development",
        },
        {
            id: "ai",
            title: "Artificial Intelligence",
        },
        {
            id: "design",
            title: "Web/Mobile Design",
        },
    ]

    useEffect(() => {
        switch(selected) {
            case "overview":
                setData(highlightsPortfolio);
                break;
            case "gameDev":
                setData(gameDevPortfolio);
                break;
            case "ai":
                setData(aiPortfolio);
                break;
            case "design":
                setData(designPortfolio);
                break;
            default:
                setData(highlightsPortfolio);
        }
    }, [selected]);

    return (
        <div className="portfolio" id="portfolio">
            <h1>Portfolio</h1>
            <ul>
                {list.map(item=>(
                    <PortfolioList
                        key={item.id}
                        title={item.title} 
                        active={selected === item.id} 
                        setSelected={setSelected}
                        id={item.id}
                    />
                ))}
            </ul>

            <div className="container">
                {data.map((d) => (
                    <div key={d.id} className="itemContainer">
                        <div className="item">
                            <a href={d.redir} target={d.target} rel="noreferrer"><img src={d.img} alt="" /></a>
                        </div>
                        <h3>{d.title}</h3>
                        <p>{d.desc}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}
